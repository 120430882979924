import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery'
import 'jquery-ui-dist/jquery-ui'
import * as bootstrap from 'bootstrap'
import $ from "expose-loader?exposes=$,jQuery!jquery"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("@fortawesome/fontawesome-free");
require ("moment");

require('@popperjs/core')
require('choices.js')
require('qr-scanner')
require('@client-side-validations/client-side-validations')
require('./nested-forms/add_fields');
require('./nested-forms/remove_fields');
require('../shared/form_errors');